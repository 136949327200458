import { EChartOption } from 'echarts'

export const getDefaultLine = (data: [string, number][]) =>
	({
		grid: {
			show: false,
			top: 35,
			bottom: 75,
			left: 75,
			right: 75,
		},
		tooltip: {
			trigger: 'axis',
		},
		xAxis: {
			min: (value) => {
				return value.min
			},
			max: (value) => {
				return value.max
			},
			name: 'version',
			type: 'time',
			axisLine: {
				lineStyle: {
					color: '#C9D3F2',
				},
				onZero: false,
			},
			splitLine: {
				show: false,
			},
		},
		yAxis: {
			name: 'index',
			type: 'value',
			axisLine: {
				lineStyle: {
					color: '#C9D3F2',
				},
				onZero: false,
			},
			splitLine: {
				show: false,
			},
		},
		series: [
			{
				data,
				type: 'line',
				smooth: true,
			},
		],
	} as EChartOption)

export const getProjectCardLineGraph = (data: [number, number][]) =>
	({
		grid: {
			show: false,
			top: 7.5,
			bottom: 5,
			left: 2.5,
			right: 2.5,
		},
		yAxis: {
			type: 'value',
			show: false,
		},
		xAxis: {
			min: (val) => val.min,
			max: (val) => val.max,
			type: 'value',
			axisLabel: {
				show: false,
			},
			splitLine: {
				show: false,
			},
			axisTick: {
				show: false,
			},
		},
		series: [
			{
				type: 'line',
				symbol: 'none',
				lineStyle: {
					color: '#00FF3C',
					width: 0.75,
				},
				data: data,
			},
		],
	} as EChartOption)
