import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Field, FieldProps } from 'formik'
import React from 'react'

interface ICustomSelectField {
  name: string
  label: string
  children: {
    value: string
    text: string
  }[]
  defaultValue?: string
  required?: boolean
}

const CustomSelectField = ({
  name,
  label,
  children,
  defaultValue,
  required,
}: ICustomSelectField) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <FormControl required={required} variant='standard'>
        <InputLabel id={`label-${field.name}`}>{label}</InputLabel>
        <Select
          labelId={`label-${field.name}`}
          id='demo-simple-select-standard'
          label={label}
          defaultValue={defaultValue}
          {...field}
        >
          {children.map(({ value, text }, i) => (
            <MenuItem value={value} key={i}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  </Field>
)

export default CustomSelectField
