/* /src/providers/authProvider.jsx */

import React, {
	Component,
	FunctionComponent,
	PropsWithChildren,
	useState,
} from 'react'
import AuthService from './authService'

const AuthContext = React.createContext<any>({
	signinRedirectCallback: () => ({}),
	logout: () => ({}),
	signoutRedirectCallback: () => ({}),
	isAuthenticated: () => false,
	signinRedirect: () => ({}),
	signinSilentCallback: () => ({}),
	createSigninRequest: () => {},
})

export const useAuth = () => React.useContext(AuthContext)

export const AuthProvider: FunctionComponent<PropsWithChildren<{}>> = ({
	children,
}) => {
	const [AuthServiceVal] = useState<AuthService>(new AuthService())

	return (
		<AuthContext.Provider value={AuthServiceVal}>
			{children}
		</AuthContext.Provider>
	)
}
