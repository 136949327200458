import {
	Button,
	Container,
	Typography,
	styled,
	CircularProgress,
	Box,
	IconButton,
	InputProps,
	Alert,
	AlertTitle,
	Collapse,
} from '@mui/material'
import { Formik, useFormikContext } from 'formik'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomRadioField, CustomTextField } from 'Components/Form'
import { SubHeader } from 'Components/Header'
import { NoDataComponent } from 'Components/Loader'
import { ITreeNode, Tree } from 'Components/Tree'
import { ITreeProjectNode } from 'Model/utils'
import { ProgrammingLanguage } from 'Model/arcan-types'
import CloseIcon from '@material-ui/icons/Close'

const EvolutionAnalysisProps = () => {
	const { values } = useFormikContext<IFormValues>()

	return values.type === 'evolution' ? (
		<Box>
			<Typography variant='subtitle1'>Evolution analysis properties</Typography>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
				<CustomTextField
					label='Start date'
					name='startDate'
					type='date'
					labelProps={{
						shrink: true,
					}}
				/>
				<CustomTextField
					label='End date'
					name='endDate'
					type='date'
					labelProps={{
						shrink: true,
					}}
				/>
				<CustomTextField
					label='Min days between commits'
					name='minDays'
					type='number'
					inputProps={{
						min: '0',
					}}
				/>
			</Box>
		</Box>
	) : (
		<></>
	)
}

interface ICustomSelectDirectoryField {
	nodes: ITreeNode[]
	checked: string[]
	expanded: string[]
	indeterminated: string[]
	setChecked: Dispatch<SetStateAction<string[]>>
	setExpanded: Dispatch<SetStateAction<string[]>>
	setIndeterminated: Dispatch<SetStateAction<string[]>>
}

const CustomSelectDirectoryField = ({
	nodes,
	checked,
	expanded,
	indeterminated,
	setChecked,
	setExpanded,
	setIndeterminated,
}: ICustomSelectDirectoryField) => {
	const { values, setValues } = useFormikContext<IFormValues>()
	const [open, setOpen] = React.useState(true);
	return values.type === 'latest' ? (
		<Box sx={{ flex: '1' }}>
			<Collapse in={open}>
			<Alert onClose={() => {setOpen(false)}} severity='info' variant='outlined' sx={{color: "white"}} >
					<AlertTitle>TIP</AlertTitle> 
					You can right click on the directories to recursively deselect all of its subdirectories.
				</Alert>
			</Collapse>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant='subtitle1'>Directories to analyse</Typography>
				<Typography variant='subtitle1'>
					Selected folders: {checked.length}
				</Typography>
			</Box>
			<Tree
				nodes={nodes}
				checked={checked}
				setChecked={setChecked}
				expanded={expanded}
				setExpanded={setExpanded}
				indeterminated={indeterminated}
				setIndeterminated={setIndeterminated}
			/>
		</Box>
	) : (
		<Box sx={{ flex: '1' }}>
			<Typography variant='subtitle1'>Path filters</Typography>
			{values.pathFilters.map((_, i) => (
				<Box sx={{ display: 'flex', gap: '16px' }} key={i}>
					<Box sx={{ flex: 1 }}>
						<CustomTextField
							name={`pathFilters[${i}].includeGlobPatterns`}
							label='Include pattern'
						/>
					</Box>
					<Box sx={{ flex: 1 }}>
						<CustomTextField
							name={`pathFilters[${i}].excludeGlobPatterns`}
							label='Exclude pattern'
						/>
					</Box>
					<IconButton
						color='secondary'
						component='span'
						onClick={() => {
							const newFilters = [...values.pathFilters]
							newFilters.splice(i, 1)

							setValues({
								...values,
								pathFilters: newFilters,
							})
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			))}
			<Box sx={{ textAlign: 'center', mb: '1rem' }}>
				<Button
					variant='outlined'
					color='secondary'
					onClick={() => {
						const newFilters = [...values.pathFilters]
						newFilters.push({
							includeGlobPatterns: '',
							excludeGlobPatterns: '',
						})

						setValues({
							...values,
							pathFilters: newFilters,
						})
					}}
				>
					Add filter
				</Button>
			</Box>
		</Box>
	)
}

const CustomAddButton = () => {
	const { values, setValues } = useFormikContext<IFormValues>()

	return (
		<Box sx={{ textAlign: 'center' }}>
			<Button
				variant='outlined'
				color='secondary'
				onClick={() => {
					const newMatcher = [...values.matcher]
					newMatcher.push({
						pattern: '',
						paths: '',
					})

					setValues({
						...values,
						matcher: newMatcher,
					})
				}}
			>
				Add directive
			</Button>
		</Box>
	)
}

const MatcherField = () => {
	const { values, setValues } = useFormikContext<IFormValues>()

	return (
		<>
			{values.matcher.map((m, i) => (
				<Box sx={{ display: 'flex', gap: '16px' }} key={i}>
					<Box sx={{ flex: 1 }}>
						<CustomTextField name={`matcher[${i}].pattern`} label='Pattern' />
					</Box>
					<Box sx={{ flex: 3 }}>
						<CustomTextField
							name={`matcher[${i}].paths`}
							label='Paths'
							placeholder='Insert multiple paths separated by ;'
						/>
					</Box>
					<IconButton
						color='secondary'
						component='span'
						onClick={() => {
							const newMatcher = [...values.matcher]
							newMatcher.splice(i, 1)

							setValues({
								...values,
								matcher: newMatcher,
							})
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			))}
		</>
	)
}

export interface ITreeInitValues {
	checked: string[]
	expanded: string[]
	indeterminated: string[]
}

export interface IFormValues {
	type: string
	matcher: {
		pattern: string
		paths: string
	}[]
	pathFilters: {
		includeGlobPatterns: string
		excludeGlobPatterns: string
	}[]
	startDate?: Date
	endDate?: Date
	minDays?: number
}

export interface IAnalysisTree {
	jsonValue?: string
	finishAction: (
		checked: string[],
		indeterminate: string[],
		expanded: string[],
		formValues: IFormValues
	) => void
	initValues?: ITreeInitValues
	completeBtnText?: string
	programmingLanguage: string
	formInitValues: IFormValues
	projectName: string
}

const mapTreeNode = (node: ITreeProjectNode, parent?: ITreeNode) => {
	const newNode: ITreeNode = {
		nodeId: node.path ?? '',
		label: node.simpleName,
		parent,
	}

	if (node.children) {
		newNode.children = node.children.map((child) => mapTreeNode(child, newNode))
	}

	return newNode
}

const getNodeToCheck = (node: ITreeProjectNode) => {
	let checked: string[] = []

	if (node.included && node.path) {
		checked.push(node.path)

		if (node.children) {
			node.children.forEach((child) => {
				const childResult = getNodeToCheck(child)
				checked = checked.concat(childResult)
			})
		}
	}

	return checked
}

const TitleContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
}))

const TreeContainer = styled('div')(() => ({
	height: 'calc(100% - 100px)',
	width: '100%',
}))

const AnalysisTree = ({
	jsonValue,
	initValues,
	finishAction,
	programmingLanguage,
	completeBtnText = 'CONTINUE',
	formRef,
	formInitValues,
	projectName,
}: IAnalysisTree & { formRef: any }) => {
	const [Checked, setChecked] = useState<string[]>([])
	const [Expanded, setExpanded] = useState<string[]>([])
	const [Indeterminated, setIndeterminated] = useState<string[]>([])
	const [Nodes, setNodes] = useState<ITreeNode[]>([])

	const navigate = useNavigate()

	useEffect(() => {
		setChecked(initValues?.checked ?? [])
		setExpanded(initValues?.expanded ?? [])
		setIndeterminated(initValues?.indeterminated ?? [])
	}, [initValues])

	useEffect(() => {
		if (jsonValue) {
			const projectNode: ITreeProjectNode = JSON.parse(jsonValue)

			if (projectNode.children?.length) {
				const nodes = projectNode.children.map((child) => mapTreeNode(child))
				setNodes(nodes)

				if (!initValues?.checked) {
					let checkedNodes: string[] = []
					let indeterminatedNodes: string[] = []
					projectNode.children.forEach((child) => {
						const childResult = getNodeToCheck(child)
						checkedNodes = checkedNodes.concat(childResult)
					})

					setChecked(checkedNodes)
					setIndeterminated(indeterminatedNodes)
				}

				if (!initValues?.expanded) {
					setExpanded([nodes[0].nodeId])
				}
			}
		}
	}, [jsonValue, initValues])

	return (
		<>
			<SubHeader
				title={projectName ?? ''}
				buttons={[
					{
						text: 'CANCEL',
						variant: 'contained',
						onClick: () => navigate('/'),
					},
				]}
			/>
			{Nodes.length ? (
				<Container
					sx={{ marginTop: '6rem', height: 'calc(100% - 6rem - 64px)' }}
					maxWidth='xl'
				>
					<TitleContainer>
						<div>
							<Typography variant='h1'>Configure analysis (1 of 2)</Typography>
							{/* <Typography variant='subtitle1'>Configure analyze</Typography> */}
						</div>
						<Button
							variant='contained'
							color='secondary'
							sx={{ margin: '0 auto auto 5rem' }}
							onClick={() =>
								finishAction(
									Checked,
									Indeterminated,
									Expanded,
									formRef.current.values
								)
							}
						>
							{completeBtnText}
						</Button>
					</TitleContainer>
					<TreeContainer>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: '32px',
								height: '100%',
							}}
						>
							<Formik
								initialValues={formInitValues}
								innerRef={formRef}
								onSubmit={() => {}}
							>
								<>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											gap: '32px',
											flex: '1',
											justifyContent: 'flex-start',
										}}
									>
										<Box sx={{ marginRight: 'auto' }}>
											<Typography variant='subtitle1'>
												Type of analysis
											</Typography>
											<CustomRadioField
												name='type'
												label=''
												children={[
													{ value: 'latest', text: 'Latest version only' },
													{
														value: 'evolution',
														text: 'Evolution analysis',
													},
												]}
											/>
										</Box>
										<EvolutionAnalysisProps />
										{(programmingLanguage == ProgrammingLanguage.C ||
											programmingLanguage == ProgrammingLanguage.Cpp) && (
											<Box sx={{ flex: '1' }}>
												<Typography variant='subtitle1'>
													Include directives resolution
												</Typography>
												<MatcherField />
												<CustomAddButton />
											</Box>
										)}
									</Box>
									<CustomSelectDirectoryField
										nodes={Nodes}
										checked={Checked}
										setChecked={setChecked}
										expanded={Expanded}
										setExpanded={setExpanded}
										indeterminated={Indeterminated}
										setIndeterminated={setIndeterminated}
									/>
								</>
							</Formik>
						</Box>
					</TreeContainer>
				</Container>
			) : (
				<NoDataComponent>
					<div>Empty tree</div>
				</NoDataComponent>
			)}
		</>
	)
}

export default AnalysisTree
