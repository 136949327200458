import { styled } from '@mui/material'
import React from 'react'

const NoDataContainer = styled('div')(({ theme }) => ({
  margin: '15rem auto 0',
  textAlign: 'center',
  color: theme.customPalette.text,
}))

const NoDataComponent: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
  children,
}) => <NoDataContainer>{children}</NoDataContainer>

export default NoDataComponent
