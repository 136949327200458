import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ThemeProvider } from '@mui/material/styles'

import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { theme } from './arcanTheme'
import { SnackbarProvider } from './Components/Context/SnackbarContext'
import { ConfirmDialogProvider } from './Components/Context/ConfirmDialogContext'
import { AuthProvider } from './OIDC/AuthContext'
import { AUTH_URL } from './OIDC/authConsts'
import { createUploadLink } from 'apollo-upload-client'

const httpUploadLink = createUploadLink({
  uri:
    window._env_.REACT_APP_GRAPHQL_SERVER_URL ||
    process.env.REACT_APP_GRAPHQL_SERVER_URL,
})

const authLink = setContext((_, { headers }) => {
  if (
    (window._env_.REACT_APP_SKIP_AUTH || process.env.REACT_APP_SKIP_AUTH) ===
    'true'
  )
    return { headers: { ...headers } }

  const val = sessionStorage.getItem(
    `oidc.user:${AUTH_URL}:${
      window._env_.REACT_APP_KC_CLIENT_ID || process.env.REACT_APP_KC_CLIENT_ID
    }`
  )
  const oidcStorage = val ? JSON.parse(val) : null

  var token = oidcStorage
    ? `${oidcStorage.token_type} ${oidcStorage.access_token}`
    : ''

  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpUploadLink),
  cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ConfirmDialogProvider>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </ConfirmDialogProvider>
        </ThemeProvider>
      </ApolloProvider>
    </AuthProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
