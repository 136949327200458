import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import WithLoading from 'Components/HOC/WithLoading'
import { useGetTreeDGLazy } from 'Queries/DependencyGraphq'
import { DependencyGraph } from 'Templates/DependencyGraph'

const DependecyGraphWithLoading = WithLoading(DependencyGraph)

const DependecyGraphData = () => {
  const { projectId, versionId } =
    useParams<{ projectId: string; versionId: string }>()
  const [getTreeDG, { loading, data }] = useGetTreeDGLazy()

  useEffect(() => {
    if (projectId && versionId) {
      getTreeDG({
        variables: {
          projectId: parseInt(projectId),
          versionId,
        },
      })
    }
  }, [projectId, versionId])

  return (
    <DependecyGraphWithLoading
      loading={loading}
      showHeader={false}
      dependencyGraph={data?.projectById?.dependencyGraph}
    />
  )
}

export default DependecyGraphData
