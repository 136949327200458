import { Box, IconButton, Link, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import {
  ArchitecturalSmell,
  DependencyEdge,
  DependencyLocation,
  EdgeSourcesTree,
} from 'Model/arcan-types'
import BaseGraph, { IBaseGraph } from 'Components/Cytoscape/BaseGraph'
import { EdgeDefinition, NodeCollection, NodeDefinition } from 'cytoscape'
import MUIDataTable from 'mui-datatables'

interface ISmellDetail {
  smell: ArchitecturalSmell
  backAction: () => void
  edges: DependencyEdge[]
  handleSelectItem: (item: NodeCollection, type: 'node' | 'edge') => void
  tableTitle: string
}

interface ISource {
  file: string
  dependant: string
  dependantUpon: string
  weight: number
  lines: string[]
  locationList: DependencyLocation[]
}

const DetailContainer = styled(Box)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: '30px 30px 0',
  width: 'calc(100vw - 60px)',
  flexWrap: 'nowrap',
  overflow: 'auto',
  height: 'calc(100% - 120px)',
}))

const SmellCardInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
}))

const SmellGraph = styled(Box)(({ theme }) => ({
  height: 'calc(100% - 220px)',
}))

const InfoValue = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  marginBottom: theme.spacing(1),
  maxWidth: '400px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const InfoKey = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  color: theme.customPalette.title,
}))

const CodeContainer = styled('code')(() => ({
  counterReset: 'line',
}))

const CodeRow = styled('div')(({ theme }) => ({
  color: theme.customPalette.text,
  '&:before': {
    color: theme.customPalette.text,
    counterIncrement: 'line',
    content: 'counter(line)',
    display: 'inline-block',
    borderRight: '1px solid #ddd',
    padding: '0 .5em',
    marginRight: '.5em',
    minWidth: '25px',
  },
}))

const HighlightCodeRow = styled('div')(({ theme }) => ({
  color: theme.customPalette.text,
  background: theme.customPalette.codeHighlight,
  '&:before': {
    color: theme.customPalette.text,
    counterIncrement: 'line',
    content: 'counter(line)',
    display: 'inline-block',
    borderRight: '1px solid #ddd',
    padding: '0 .5em',
    marginRight: '.5em',
    minWidth: '25px',
  },
}))

const SmellDetail = ({
  smell,
  backAction,
  edges,
  handleSelectItem,
  tableTitle,
}: ISmellDetail) => {
  const [GraphOptions, setGraphOptions] = useState<IBaseGraph>({
    nodes: [],
    edges: [],
    graphLayout: { nodeLabel: '', edgeLabel: '' },
    tapHandler: evt => {
      handleSelectItem(evt.target as NodeCollection, 'node')
    },
    tapEdgeHandler: evt =>
      handleSelectItem(evt.target as NodeCollection, 'edge'),
  })
  const [Sources, setSources] = useState<ISource[]>([])

  useEffect(() => {
    if (smell?.smellDependencyEdges) {
      const dependencyEdges = smell.smellDependencyEdges ?? []
      const smellProps = new Map(
        smell.properties?.map(object => {
          return [object!.key, object!.value]
        })
      )

      let nodes: NodeDefinition[] = []
      let edges: EdgeDefinition[] = []

      dependencyEdges?.forEach(edge => {
        edges.push({
          group: 'edges',
          data: {
            id: `${edge?.id}`,
            source: `${edge?.dependant?.id}`,
            target: `${edge?.dependedUpon?.id}`,
            label: edge?.label,
            lineColor: '#19B2FF',
            weight: '',
          },
        })

        if (nodes.every(n => n.data.id !== edge?.dependant?.id)) {
          nodes.push({
            group: 'nodes',
            data: {
              id: `${edge?.dependant?.id}`,
              label: edge?.dependant?.name,
              name: edge?.dependant?.name,
            },
          })
        }

        if (nodes.every(n => n.data.id !== edge?.dependedUpon?.id)) {
          nodes.push({
            group: 'nodes',
            data: {
              id: `${edge?.dependedUpon?.id}`,
              label: edge?.dependedUpon?.name,
              name: edge?.dependedUpon?.name,
            },
          })
        }
      })

      setGraphOptions({
        nodes,
        edges,
        graphLayout: {
          nodeLabel: 'label',
          edgeLabel: '',
        },
        tapHandler: () => {},
        defaultLayout:
          smellProps?.get('smellType') === 'cyclicDep'
            ? 'circle'
            : 'concentric',
      })
    }
  }, [smell])

  useEffect(() => {
    const sources: ISource[] = edges.reduce((acc, edge) => {
      const dependantProps = new Map(
        edge.dependant.properties?.map(prop => {
          return [prop!.key as string, prop!.value as string]
        })
      )

      const existingSource = acc.find(s => s.dependant === edge.dependant.name)
      if (existingSource) {
        existingSource.dependantUpon = `${existingSource.dependantUpon}, ${edge.dependedUpon.name}`
        existingSource.lines =
          edge.codeSnippet?.fileLines?.length ?? 0 > existingSource.lines.length
            ? edge.codeSnippet.fileLines
            : existingSource.lines
        existingSource.locationList = existingSource.locationList.concat(
          edge.codeSnippet?.locations ?? []
        )
      } else {
        acc.push({
          file: dependantProps.get('filePathRelative') ?? '',
          dependant: edge.dependant.name,
          dependantUpon: edge.dependedUpon.name,
          weight: edge.weight,
          lines: edge.codeSnippet?.fileLines ?? [],
          locationList: edge.codeSnippet?.locations ?? [],
        })
      }

      return acc
    }, [] as ISource[])

    setSources(sources)
  }, [edges])

  const props = new Map(
    smell?.properties?.map(prop => [prop?.key, prop?.value])
  )

  const infos = [
    {
      key: 'Id',
      value: smell?.id,
    },
    {
      key: 'Smell Type',
      value: smell?.fullTypeName,
    },
    {
      key: 'TechDebt Index',
      value: parseInt(props.get('ATDI') ?? '0'),
    },
    {
      key: 'Severity',
      value: parseInt(props.get('Severity') ?? '0'),
    },
    {
      key: 'Affected Type',
      value: smell?.affectedConstructType?.prettyName ?? '',
    },
    {
      key: 'Affected Element(s)',
      value:
        smell?.affectedComponents?.map(comp => comp?.name ?? '').join(', ') ??
        '',
    },
    {
      key: 'Size',
      value: smell?.size ?? 0,
    },
  ]

  return (
    <DetailContainer>
      <SmellCardInfo>
        <IconButton color='secondary' onClick={backAction}>
          <ChevronLeftIcon />
        </IconButton>
        {infos.map((info, i) => (
          <Box key={i}>
            <InfoValue>{info.value}</InfoValue>
            <InfoKey>{info.key}</InfoKey>
          </Box>
        ))}
        <Box key="refactor-wiki" sx={{ marginLeft: 'auto' }}>
          <Link href='https://docs.arcan.tech/2.8.0/refactoring/' target='_blank' color='primary'>
            How to refactor?
          </Link>
        </Box>
      </SmellCardInfo>
      <SmellGraph>
        <BaseGraph {...GraphOptions} />
      </SmellGraph>
      <Box className='myDataTable'>
        <MUIDataTable
          title={tableTitle}
          data={Sources}
          columns={[
            {
              name: 'file',
              label: 'File',
            },
            {
              name: 'dependant',
              label: 'Dependant (Out)',
            },
            {
              name: 'dependantUpon',
              label: 'Depends upon (In)',
            },
            {
              name: 'weight',
              label: 'Weight',
            },
          ]}
          options={{
            selectableRows: 'none',
            expandableRowsHeader: false,
            expandableRows: true,
            renderExpandableRow: (rowData, rowMeta) => {
              const source = Sources.find(s => s.file === rowData[0])
              const lines = source?.lines ?? []
              const highlightedLines = source?.locationList ?? []

              return (
                <>
                  <tr className='separator'></tr>
                  <tr>
                    <td colSpan={5}>
                      <pre>
                        <CodeContainer>
                          {lines.map((l, i) => {
                            return highlightedLines.some(
                              l => l.line - 1 <= i && l.endLine - 1 >= i
                            ) ? (
                              <HighlightCodeRow>{l}</HighlightCodeRow>
                            ) : (
                              <CodeRow>{l}</CodeRow>
                            )
                          })}
                        </CodeContainer>
                      </pre>
                    </td>
                  </tr>
                </>
              )
            },
            textLabels: {
              body: {
                noMatch:
                  'Click either an edge or a node to view where dependencies are created.',
              },
            },
          }}
        />
      </Box>
    </DetailContainer>
  )
}

export default SmellDetail
