import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectCard } from 'Components/Card'
import { useConfirmDialog } from 'Components/Context/ConfirmDialogContext'
import { useSnackbar } from 'Components/Context/SnackbarContext'
import WithLoading from 'Components/HOC/WithLoading'
import { Project } from 'Model/arcan-types'
import { Projects } from 'Templates/Projects'
import { useAnalyseProjectByNameMutation } from 'Queries/AnalyseProject'
import {
	GET_ALL,
	useDeleteProject,
	useGetAllProjects,
	useGetJobs,
	useGetJobsLazy,
} from '../../Queries/Projects'

const ProjectsWithLoading = WithLoading(Projects)

interface IProjectCardData {
	project: Project
	handleAnalyze: (projectId: number, projectName: string) => void
	handleDelete: (projectId: number, projectName: string) => void
	handleUpdate: (projectId: number) => void
	disabled: boolean
	loading: boolean
}

const ProjectCardData = ({
	project,
	handleAnalyze,
	handleDelete,
	handleUpdate,
	disabled,
	loading,
}: IProjectCardData) => {
	return (
		<ProjectCard
			project={project}
			loading={loading}
			analyze={handleAnalyze}
			update={handleUpdate}
			deleteProject={handleDelete}
			disabled={disabled}
		/>
	)
}

const ProjectsData = () => {
	const { loading, projects, demoMode, refetch } = useGetAllProjects()
	const { loading: loadingJobs, jobs } = useGetJobs()
	const [_, { data, stopPolling, startPolling }] = useGetJobsLazy()
	const [analyseProjectById, { loading: loadingAnalyse }] =
		useAnalyseProjectByNameMutation()
	const [deleteProject, { loading: loadingDelete }] = useDeleteProject()
	const { handleOpen } = useSnackbar()
	const { openDialog } = useConfirmDialog()
	const navigate = useNavigate()

	useEffect(() => {
		if (jobs?.length) {
			startPolling(1000)
		}
	}, [jobs])

	useEffect(() => {
		if (data?.allJobs && !data?.allJobs?.length) {
			stopPolling()
			refetch()
		}
	}, [data])

	const handleAnalyze = (projectId: number, projectName: string) => {
		openDialog({
			open: true,
			title: 'Analyze project',
			body: `Are you sure to analyze the project ${projectName}?`,
			btnConfirmText: 'Analyze',
			confirmAction: () => {
				analyseProjectById({
					variables: { projectId },
					refetchQueries: [GET_ALL],
				})
					.then(({ data }) => {
						if (data?.analyseProjectById) {
							handleOpen('Start project analysis', 'info')
							startPolling(1000)
						}
					})
					.catch((err) => handleOpen(`${err}`, 'error'))
			},
		})
	}

	const handleDelete = (projectId: number, projectName: string) => {
		openDialog({
			open: true,
			title: 'Delete project',
			body: `Are you sure to delete the project ${projectName}?`,
			btnConfirmText: 'Delete',
			confirmAction: () => {
				deleteProject({
					variables: {
						projectId,
					},
					refetchQueries: [GET_ALL],
				})
					.then(({ data }) => {
						if (data?.removeProject) {
							handleOpen('Project deleted!', 'success')
						} else {
							handleOpen('Error on deleting project!', 'error')
						}
					})
					.catch((err) => handleOpen(`${err}`, 'error'))
			},
		})
	}

	const laodingProjects = data?.allJobs?.map((j) => j.targetResourceId) ?? []
	return (
		<ProjectsWithLoading
			loading={loading}
			projectsCount={projects.length}
			showBackdrop={loadingAnalyse || loadingDelete || loadingJobs}
			demoMode={demoMode}
		>
			{projects.map((project, i) => (
				<ProjectCardData
					key={i}
					project={project}
					disabled={demoMode}
					handleAnalyze={handleAnalyze}
					handleDelete={handleDelete}
					handleUpdate={(projectId) =>
						navigate(`/project/edit/${projectId}/tree`)
					}
					loading={laodingProjects.includes(project.id)}
				/>
			))}
		</ProjectsWithLoading>
	)
}

export default ProjectsData
