import { styled } from '@mui/material'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { Header } from '../Header'

const PageContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 64px)',
  background: theme.customPalette.appBackgroud,
  color: theme.customPalette.text,
  width: '100%',
  overflow: 'auto',
}))

const Layout: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <Header />
      <PageContainer>{children}</PageContainer>
    </>
  )
}

export default Layout
