import {
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import React from 'react'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: '1px solid #FFFFFF4A',
  borderRadius: theme.shape.borderRadius,
  '& .MuiToggleButton-root': {
    color: theme.palette.primary.main,
    border: '0',
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: 14,
    '&.MuiToggleButtonGroup-grouped': {
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiSvgIcon-root': {
      width: '15px',
      marginRight: theme.spacing(0.5),
    },
  },
  '& .MuiToggleButtonGroup-grouped.Mui-selected': {
    color: theme.customPalette.title,
    background: '#FFFFFF4A',
    '&:hover': {
      background: '#FFFFFF4A',
    },
  },
  marginRight: '1rem',
}))

const ContainerToggleButtonGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.customPalette.text,
}))

const StyledTypography = styled(Typography)(() => ({
  margin: 'auto 1rem auto 0',
}))

interface ISimpleButtonProps {
  selected: string
  label: string
  buttons?: {
    value: string
    text: string
    icon: React.ReactElement
    action: () => void
  }[]
}

const SimpleButtonGroup = ({
  selected,
  label,
  buttons,
}: ISimpleButtonProps) => {
  return (
    <ContainerToggleButtonGroup>
      {buttons && (
        <StyledToggleButtonGroup value={selected} aria-label='Platform'>
          {buttons?.map((btn, i) => (
            <ToggleButton key={i} value={btn.value} onClick={btn.action}>
              {btn.icon}
              {btn.text}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      )}
      <StyledTypography variant='h2'>{label}</StyledTypography>
    </ContainerToggleButtonGroup>
  )
}

export default SimpleButtonGroup
