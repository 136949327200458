import React from 'react'
import CardContent from '@mui/material/CardContent'
import { Typography } from '@mui/material'
import Chart from 'Components/Chart/Chart'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import InfoIcon from '../../img/icons/info-icon.svg'
import Tooltip from '@mui/material/Tooltip'
import SquareIcon from '@mui/icons-material/Square'
import { TrendDataPoint } from 'Model/arcan-types'

interface IOverviewCard {
	projectOverviewCard: {
		title: string
		value?: TrendDataPoint
		um: string
		icon?: {
			src: string
			alt: string
		}
		tooltip: string
		type?: string
		chart?: {
			data?: any
			target?: String
			legenda?: {
				color: string
				description: string
			}[]
		}
	}
}

const TrendDataValue = ({
	data,
	um,
}: {
	data?: TrendDataPoint
	um: string
}) => {
	data =
		data ??
		({ value: 0, trendPercentage: 0, trend: 'NEUTRAL' } as TrendDataPoint)

	const defaultIconProps = {
		margin: 'auto 0 auto .25rem',
	}
	const icon =
		data.trendPercentage > 0 ? (
			<ArrowDropUpIcon sx={defaultIconProps} />
		) : data.trendPercentage < 0 ? (
			<ArrowDropDownIcon sx={defaultIconProps} />
		) : (
			<DragHandleIcon sx={{ ...defaultIconProps, fontSize: 24 }} />
		)

	const color =
		data?.trend === 'POSITIVE'
			? 'green'
			: data?.trend === 'NEGATIVE'
			? 'red'
			: 'silver'
	return (
		<Typography fontSize={40}>
			{data.value ?? 0}
			{um}
			<Typography
				fontSize={24}
				color={color}
				sx={{ float: 'right', marginTop: '18px' }}
			>
				{data.trendPercentage}%{icon}
			</Typography>
		</Typography>
	)
}

const OverviewCard = ({ projectOverviewCard }: IOverviewCard) => {
	return (
		<CardContent className='cardoverview'>
			<h2>
				{projectOverviewCard.icon ? (
					<img
						src={projectOverviewCard.icon.src}
						alt={projectOverviewCard.icon.alt}
						style={{ marginRight: '8px' }}
					/>
				) : null}
				{projectOverviewCard.title}{' '}
				<Tooltip title={projectOverviewCard.tooltip}>
					<img src={InfoIcon} alt='info icon' />
				</Tooltip>
			</h2>
			{projectOverviewCard.chart ? (
				projectOverviewCard.chart.target ? (
					<>
						<div style={{ display: 'flex' }}>
							<div style={{ width: '50%' }}>
								<Typography fontSize={16} className='darktext'>
									Highest technical debt concentration:
								</Typography>
								<Typography
									fontSize={18}
									className='darktext'
									sx={{ fontWeight: 'bolder' }}
								>
									{projectOverviewCard.chart.target}
								</Typography>
								<h4>Legend</h4>
								{projectOverviewCard.chart.legenda
									? projectOverviewCard.chart.legenda.map((el) => (
											<Typography fontSize={16} className='darktext'>
												<SquareIcon
													sx={{ width: '16px', height: '16px', fill: el.color }}
												/>
												{el.description}
											</Typography>
									  ))
									: null}
							</div>
							<div style={{ width: '50%' }}>
								<div style={{ display: 'flex', height: 'calc(100% - 20px)' }}>
									<Chart options={projectOverviewCard.chart.data} />
								</div>
							</div>
						</div>
					</>
				) : (
					<div style={{ display: 'flex', height: 'calc(100% - 20px)' }}>
						<Chart options={projectOverviewCard.chart.data} />
					</div>
				)
			) : (
				<div className='values'>
					<TrendDataValue
						data={projectOverviewCard.value}
						um={projectOverviewCard.um}
					/>
					<Typography fontSize={16} className='darktext'>
						{projectOverviewCard.type ? projectOverviewCard.type : <br />}
					</Typography>
				</div>
			)}
		</CardContent>
	)
}

export default OverviewCard
