import { Grid } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import GraphEditorColor from 'Components/Form/GraphEditor/GraphEditorColor'
import GraphEditorGroup from 'Components/Form/GraphEditor/GraphEditorGroup'
import GraphEditorOption from 'Components/Form/GraphEditor/GraphEditorOption'
import GraphEditorPresets from 'Components/Form/GraphEditor/GraphEditorPresets'
import GraphEditorSelect from 'Components/Form/GraphEditor/GraphEditorSelect'
import GraphEditorSubGroup from 'Components/Form/GraphEditor/GraphEditorSubGroup'
import { IColorOption, IEditorValues } from './DependencyGraph'

const presets = [
  {
    text: 'Organic',
    value: 'organic',
  },
]

const labels = {
  edges: [
    {
      value: 'none',
      text: 'None',
    },
    {
      value: 'id',
      text: 'Edge ID',
    },
    {
      value: 'label',
      text: 'Label',
    },
    {
      value: 'weight',
      text: 'Weight',
    },
  ],
  nodes: [
    {
      value: 'none',
      text: 'None',
    },
    {
      value: 'id',
      text: 'Vertex ID',
    },
    {
      value: 'name',
      text: 'Full name',
    },
    {
      value: 'simpleName',
      text: 'Simple name',
    },
    {
      value: 'label',
      text: 'Label',
    },
    {
      value: 'constructType',
      text: 'Construct type',
    },
    {
      value: 'filePathRelative',
      text: 'Path',
    },
  ],
}

interface IEditorOption {
  type: 'Group' | 'SubGroup' | 'Option' | 'Select' | 'Color'
  id: string
  label?: string
  items?: {
    value: string
    text: string
  }[]
  children?: IEditorOption[]
}

const editorProps: IEditorOption[] = [
  {
    type: 'Group',
    label: 'Nodes',
    id: 'groupNodes',
    children: [
      {
        type: 'Option',
        label: 'Label',
        id: 'optionLabel',
        children: [
          {
            type: 'Select',
            id: 'labelNodes',
            label: 'Select prop.',
            items: labels.nodes,
          },
        ],
      },
      {
        type: 'Option',
        label: 'Container',
        id: 'optionContainer',
        children: [
          {
            type: 'Color',
            id: 'container',
          },
        ],
      },
      {
        type: 'Option',
        label: 'Unit',
        id: 'optionUnit',
        children: [
          {
            type: 'Color',
            id: 'unit',
          },
        ],
      },
    ],
  },
  {
    type: 'Group',
    id: 'groupEdge',
    label: 'Edges',
    children: [
      {
        type: 'Option',
        label: 'Label',
        id: 'optionLabel',
        children: [
          {
            type: 'Select',
            id: 'labelEdges',
            label: 'Select prop.',
            items: labels.edges,
          },
        ],
      },
      {
        type: 'SubGroup',
        label: 'Hierarchy edges',
        id: 'subGroupHierarchy',
        children: [
          {
            type: 'Option',
            label: 'Inheritance',
            id: 'optionInheritance',
            children: [
              {
                type: 'Color',
                id: 'inheritance',
              },
            ],
          },
          {
            type: 'Option',
            label: 'Implementation',
            id: 'optionImplementation',
            children: [
              {
                type: 'Color',
                id: 'implementation',
              },
            ],
          },
        ],
      },
      {
        type: 'SubGroup',
        label: 'Membership edges',
        id: 'subGroupMembership',
        children: [
          {
            type: 'Option',
            label: 'Membership',
            id: 'optionMembership',
            children: [
              {
                type: 'Color',
                id: 'membership',
              },
            ],
          },
          {
            type: 'Option',
            label: 'Nested',
            id: 'optionNested',
            children: [
              {
                type: 'Color',
                id: 'nested',
              },
            ],
          },
        ],
      },
      {
        type: 'SubGroup',
        label: 'Dependency edges',
        id: 'subGroupDependency',
        children: [
          {
            type: 'Option',
            label: 'Dependency',
            id: 'optionDependency',
            children: [
              {
                type: 'Color',
                id: 'dependency',
              },
            ],
          },
          {
            type: 'Option',
            label: 'Container dependency',
            id: 'optionContainerDependency',
            children: [
              {
                type: 'Color',
                id: 'containerDependency',
              },
            ],
          },
        ],
      },
    ],
  },
]

const renderEditorOption = (
  option: IEditorOption,
  setValue: Dispatch<SetStateAction<IEditorValues>>,
  values: IEditorValues
) => {
  switch (option.type) {
    case 'Group':
      return (
        <GraphEditorGroup label={option.label ?? ''}>
          {option.children?.map(child =>
            renderEditorOption(child, setValue, values)
          )}
        </GraphEditorGroup>
      )
    case 'SubGroup':
      return (
        <GraphEditorSubGroup label={option.label ?? ''}>
          {option.children?.map(child =>
            renderEditorOption(child, setValue, values)
          )}
        </GraphEditorSubGroup>
      )
    case 'Option':
      return (
        <GraphEditorOption label={option.label ?? ''}>
          {option.children?.map(child =>
            renderEditorOption(child, setValue, values)
          )}
        </GraphEditorOption>
      )
    case 'Color':
      return (
        <GraphEditorColor
          selected={(values[option.id] as IColorOption)?.selected ?? false}
          color={(values[option.id] as IColorOption)?.color ?? '#000000'}
          setSelected={selected =>
            setValue(prev => ({
              ...prev,
              [option.id]: {
                ...(prev[option.id] as IColorOption),
                selected,
              },
            }))
          }
          setColor={color =>
            setValue(prev => ({
              ...prev,
              [option.id]: {
                ...(prev[option.id] as IColorOption),
                color,
              },
            }))
          }
        />
      )
    case 'Select':
      return (
        <GraphEditorSelect
          items={option.items ?? []}
          label={option.label ?? ''}
          value={(values[option.id] as string) ?? ''}
          handleChange={value => {
            setValue(prev => ({
              ...prev,
              [option.id]: value,
            }))
          }}
        />
      )
  }
}

interface IDependecyGraphEditor {
  editorValues: IEditorValues
  setEditorValues: Dispatch<SetStateAction<IEditorValues>>
}

const DependencyGraphEditor = ({
  editorValues,
  setEditorValues,
}: IDependecyGraphEditor) => {
  return (
    <>
      {/* <GraphEditorPresets items={presets} /> */}
      {editorProps.map(prop =>
        renderEditorOption(prop, setEditorValues, editorValues)
      )}
    </>
  )
}

export default DependencyGraphEditor
