import {
  Typography,
  Container,
  FormGroup,
  Chip,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@mui/material'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { SubHeader } from 'Components/Header'
import { NoDataComponent } from 'Components/Loader'
import { ProgrammingLanguage } from 'Model/arcan-types'

export interface ISelectChip {
  value: string
  text: string
  selected: boolean
}

export interface ISelectAlgorithm {
  value: string
  title: string
  chips: ISelectChip[]
  order: number
}

export interface ISelectFrameworks {
  isSpringProject: boolean
}

interface ISelectFrameworksState {
  setSelectedFrameworks: Dispatch<SetStateAction<ISelectFrameworks>>
}

interface IAlgorithmGroupComponent {
  setSelectedAlgorithms: Dispatch<SetStateAction<ISelectAlgorithm[]>>
}

const AlgoritmGroupComponent = ({
  value,
  title,
  chips,
  setSelectedAlgorithms,
}: ISelectAlgorithm & IAlgorithmGroupComponent) => {
  const [Expanded, setExpanded] = useState(false)

  const allSelected = chips.every(chip => chip.selected)
  const indeterminate = !allSelected ? chips.some(chip => chip.selected) : false

  return (
    <Accordion expanded={Expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!Expanded)} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Checkbox
          sx={{ padding: 0, mr: 1 }}
          indeterminate={indeterminate}
          checked={allSelected}
          onClick={() => {
            setSelectedAlgorithms(previous => {
              const algo = previous.find(a => a.value === value)
              algo?.chips.forEach(chip => (chip.selected = !allSelected))

              const newValues = JSON.parse(JSON.stringify(previous))
              return newValues
            })
          }}
        />
        <Typography variant='h2'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
          {chips.map((chip, i) => (
            <Chip
              className={chip.selected ? 'chip-selected' : ''}
              avatar={chip.selected ? <DoneIcon /> : <></>}
              label={chip.text}
              key={`switch-${i}`}
              onClick={() => {
                setSelectedAlgorithms(prevValues => {
                  const algoIndex = prevValues.findIndex(a => a.value === value)
                  const algo = prevValues[algoIndex]
                  const chipIndex = algo.chips.findIndex(
                    c => c.value === chip.value
                  )

                  algo.chips[chipIndex] = {
                    ...chip,
                    selected: !chip.selected,
                  }

                  const newValues = JSON.parse(JSON.stringify(prevValues))
                  newValues[algoIndex] = algo
                  return newValues
                })
              }}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )
}

const SelectFrameworksComponent = ({setSelectedFrameworks} : ISelectFrameworksState) => {
  const onChangeEvent = (_: ChangeEvent, checked: boolean) => {
    setSelectedFrameworks({isSpringProject: checked})
  }
  return <>
    <Typography variant='subtitle1'>Frameworks</Typography>
    <FormGroup>
      <FormControlLabel control={<Checkbox name='isSpringProject' onChange={onChangeEvent} ></Checkbox>} label="Is this a Spring project?" ></FormControlLabel>
    </FormGroup>
  </>
}

interface IAnalysisTags {
  initSelectedAlgorithms: ISelectAlgorithm[]
  continueAction: (algs: ISelectAlgorithm[], frameworks: ISelectFrameworks) => void
  projectName: string
  projectLanguage: ProgrammingLanguage
  cancelAction: () => void
  backAction: (arg0: ISelectAlgorithm[]) => void
  submitting: boolean
  completeBtnText?: string
}

const AnalysisTags = ({
  initSelectedAlgorithms,
  continueAction,
  projectName,
  cancelAction,
  backAction,
  completeBtnText,
  projectLanguage,
  submitting,
}: IAnalysisTags) => {
  const [SelectedAlgorithms, setSelectedAlgorithms] = useState<
    ISelectAlgorithm[]
  >([])
  
  const [SelectedFrameworks, setSelectedFrameworks] = useState<ISelectFrameworks>({isSpringProject: false})

  useEffect(() => {
    setSelectedAlgorithms(initSelectedAlgorithms ?? [])
  }, [initSelectedAlgorithms])

  return (
    <>
      <SubHeader
        title={projectName}
        buttons={[
          {
            text: 'BACK',
            variant: 'outlined',
            onClick: () => backAction(SelectedAlgorithms),
          },
          {
            text: 'CANCEL',
            variant: 'contained',
            onClick: cancelAction,
          },
        ]}
      />
      {SelectedAlgorithms.length ? (
        <Container sx={{ marginTop: '6rem' }} maxWidth='xl'>
          <Typography variant='h1'>Configure Analysis (2 of 2)</Typography>
          <Typography variant='subtitle1' fontSize='20px'>
            Select parameters for Project Analysis Configuration. Click to
            select or deselect options
          </Typography>
          <Typography variant="subtitle1">Algorithms</Typography>
          {SelectedAlgorithms.sort((a, b) => a.order - b.order).map(
            (names, i) => {
              return names ? (
                <AlgoritmGroupComponent
                  {...names}
                  setSelectedAlgorithms={setSelectedAlgorithms}
                  key={i}
                />
              ) : (
                <div />
              )
            }
          )}
          {projectLanguage === ProgrammingLanguage.Java && (
           <SelectFrameworksComponent setSelectedFrameworks={setSelectedFrameworks}></SelectFrameworksComponent>
          )}
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              marginTop: '1rem',
            }}
            onClick={() => continueAction(SelectedAlgorithms, SelectedFrameworks)}
            disabled={submitting}
          >
            {submitting ? (
              <CircularProgress
                color='inherit'
                size={24}
                sx={{ margin: '0 1.4rem' }}
              />
            ) : (
              completeBtnText
            )}
          </Button>
        </Container>
      ) : (
        <NoDataComponent>
          <div>No algorithms</div>
        </NoDataComponent>
      )}
    </>
  )
}

export default AnalysisTags
