import { EChartOption } from 'echarts'
import { useField } from 'formik'

export const getDefaultGauge = (value: number) =>
	({
		grid: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		series: [
			{
				type: 'gauge',
				min: 0,
				max: 100,
				startAngle: 200,
				endAngle: -20,
				center: ['50%', '60%'],
				// progress: {
				//   show: true,
				//   width: 14,
				// },
				axisLine: {
					lineStyle: {
						width: 6,
					},
				},
				axisTick: {
					show: false,
				},
				splitLine: {
					length: 10,
					lineStyle: {
						width: 2,
						color: '#C9D3F2',
					},
				},
				axisLabel: {
					show: false,
					distance: 0,
					color: '#C9D3F2',
					fontSize: 10,
					formatter: (value: number) => {
						return Math.round(value) + '%'
					},
				},
				// anchor: {
				//   show: true,
				//   showAbove: true,
				//   size: 25,
				//   itemStyle: {
				//     borderWidth: 10,
				//   },
				// },
				title: {
					offsetCenter: [0, '25%'],
					fontSize: 20,
					color: '#C9D3F2',
				},
				detail: {
					//valueAnimation: true,
					fontSize: 26,
					offsetCenter: [0, '0%'],
					color: '#C9D3F2',
					formatter: (value: number) => {
						return Math.round(value) + '%'
					},
				},
				pointer: {
					width: 5,
					length: 30,
					offsetCenter: [0, '-45%'],
				},
				data: [
					{
						value
					},
				],
				radius: '100%',
			},
		],
	} as any)

export const getColoredGauge = (value: number) =>
	({
		grid: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		series: [
			{
				type: 'gauge',
				center: ['50%', '60%'],
				startAngle: 200,
				endAngle: -20,
				min: 0,
				max: 100,
				splitNumber: 10,
				axisLine: {
					lineStyle: {
						width: 6,
						color: [
							[0.3, '#7CFFB2'],
							[0.7, '#FDDD60'],
							[1, '#FF6E76'],
						],
					},
				},
				pointer: {
					show: true,
					width: 5,
					length: 30,
					offsetCenter: [0, '-45%'],
					itemStyle: {
						color: 'auto',
					},
				},
				axisTick: {
					show: false,
					lineStyle: {
						color: 'inherit',
						width: 2,
					},
				},
				splitLine: {
					length: 10,
					lineStyle: {
						color: 'auto',
						width: 2.5,
					},
				},
				axisLabel: {
					color: '#C9D3F2',
					fontSize: '8px'
				},
				title: {},
				detail: {
					fontSize: 26,
					offsetCenter: [0, '0%'],
					formatter: (value: number) => {
						return Math.round(value) + '%'
					},
					color: 'inherit',
				},
				data: [
					{
						value: value,
					},
				],
				radius: '100%',
			},
		],
	} as any)


export const getSmellsGauge = (current: number, threshold:number) => {
	current = threshold < 1 ? current * 100 : current
	const max = threshold < 1 ? 100 : threshold * 1.2
	const thresholdRatio = threshold > 1 ? threshold/max : threshold
	return ({
		grid: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		series: [
			{
			  type: 'gauge',
			  max: max,
			  axisLine: {
				lineStyle: {
				  width: 15,
				  color: [
					[thresholdRatio, '#37a2da'],
					[1, '#fd666d']
				  ]
				}
			  },
			  pointer: {
				itemStyle: {
				  color: 'auto'
				}
			  },
			  axisTick: {
				distance: -30,
				length: 8,
				lineStyle: {
				  color: '#fff',
				  width: 2
				}
			  },
			  splitLine: {
				distance: -30,
				length: 30,
				lineStyle: {
				  color: '#fff',
				  width: 4
				}
			  },
			  axisLabel: {
				color: 'auto',
				distance: 40,
				fontSize: 12
			  },
			  detail: {
				valueAnimation: true,
				color: 'auto'
			  },
			  data: [
				{
				  value: current
				}
			  ]
			}
		  ],
	} as any)
}