import { gql, useQuery } from '@apollo/client'

interface IGetEnum {
  __type: {
    name: string
    enumValues: {
      name: string
      description: string
    }[]
  }
}

export const GET_ENUM = gql`
  query getEnum($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
        description
      }
    }
  }
`

export const useGetEnum = (name: string) => {
  const { data, loading } = useQuery<IGetEnum, { name: string }>(GET_ENUM, {
    variables: {
      name,
    },
  })

  const type = data
    ? data.__type
    : {
        enumValues: [],
      }

  return {
    loading,
    enumValues: type?.enumValues ?? [],
  }
}
