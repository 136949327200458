import React from 'react'
import {
	AppBar,
	styled,
	Container,
	Toolbar,
	Link,
	IconButton,
} from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'
import LogoutIcon from '@mui/icons-material/Logout'
import { useLocation, useNavigate } from 'react-router-dom'

import logo from '../../img/ArcanLogoDark.png'
import { useAuth } from '../../OIDC/AuthContext'

const HeaderContainer = styled(AppBar)(({ theme }) => ({
	background: theme.customPalette.darkblue,
}))

const SelectedIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.customPalette.text,
}))

const Header = () => {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { logout } = useAuth()

	const buttons = [
		{
			path: '/',
			icon: <AppsIcon />,
			action: () => {
				navigate('/')
			},
		},
		{
			action: logout,
			icon: <LogoutIcon />,
		},
	]

	return (
		<HeaderContainer position='static'>
			<Container maxWidth={false}>
				<Toolbar disableGutters>
					<Link href='/' sx={{ marginRight: 'auto' }}>
						<img src={logo} height={25} alt='arcan logo header' />
					</Link>
					<Link href='https://docs.arcan.tech/' target='_blank' sx={{ marginLeft: 'auto' }}>
						Docs
					</Link>
					{buttons.map((btn, i) => {
						const Button =
							btn.path === pathname ? SelectedIconButton : IconButton

						return (
							<Button key={i} color='secondary' onClick={btn.action}>
								{btn.icon}
							</Button>
						)
					})}
				</Toolbar>
			</Container>
		</HeaderContainer>
	)
}

export default Header
