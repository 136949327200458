import { Snackbar, Alert } from '@mui/material'
import React, { useState } from 'react'

const SnackbarContext = React.createContext({
  handleOpen: (
    message: string,
    variant?: 'success' | 'info' | 'error' | 'warning',
    autoHideDuration?: number
  ) => {},
  handleClose: () => {},
})

interface ISnackbarProps {
  message: string
  variant: 'success' | 'info' | 'error' | 'warning'
  autoHideDuration: number
  open: boolean
}

export const SnackbarProvider: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [SnackbarProps, setSnackbarProps] = useState<ISnackbarProps>({
    message: '',
    variant: 'success',
    autoHideDuration: 0,
    open: false,
  })

  const handleClose = () => {
    setSnackbarProps(prev => ({ ...prev, open: false }))
  }

  const handleOpen = (
    message: string,
    variant: 'success' | 'info' | 'error' | 'warning' = 'info',
    autoHideDuration: number = 5000
  ) => {
    setSnackbarProps({
      message,
      variant,
      autoHideDuration,
      open: true,
    })
  }

  return (
    <SnackbarContext.Provider value={{ handleOpen, handleClose }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={SnackbarProps.open}
        autoHideDuration={SnackbarProps.autoHideDuration ?? 5000}
        onClose={handleClose}
      >
        <Alert
          severity={SnackbarProps.variant}
          onClose={handleClose}
          variant='filled'
        >
          {SnackbarProps.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => React.useContext(SnackbarContext)
