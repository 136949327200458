import { InputBaseComponentProps } from '@material-ui/core'
import { InputLabelProps, TextField } from '@mui/material'
import { Field, FieldProps } from 'formik'
import React from 'react'

interface ICustomTextField {
  name: string
  label: string
  type?: string
  required?: boolean
  placeholder?: string
  labelProps?: InputLabelProps
  inputProps?: InputBaseComponentProps
  validate?: (arg: string) => string | null
}

const CustomTextField = ({
  name,
  label,
  type,
  required,
  placeholder,
  labelProps,
  inputProps,
  validate,
}: ICustomTextField) => (
  <Field name={name} validate={validate}>
    {({ field, meta }: FieldProps) => (
      <TextField
        error={!!meta?.error}
        label={label}
        variant='standard'
        type={type ?? 'text'}
        required={required}
        placeholder={placeholder}
        InputLabelProps={labelProps}
        inputProps={inputProps}
        helperText={meta?.error ?? ''}
        {...field}
      />
    )}
  </Field>
)

export default CustomTextField
