import { IDENTITY_CONFIG, METADATA_OIDC, AUTH_URL } from './authConsts'
import { UserManager, WebStorageStateStore, Log } from 'oidc-client'

export default class AuthService {
	UserManager

	constructor() {
		this.UserManager = new UserManager({
			...IDENTITY_CONFIG,
			userStore: new WebStorageStateStore({ store: window.sessionStorage }),
			metadata: {
				...METADATA_OIDC,
			},
		})
		// Logger
		Log.logger = console
		Log.level = Log.DEBUG
		this.UserManager.events.addUserLoaded((user) => {
			if (window.location.href.indexOf('signin-oidc') !== -1) {
				this.navigateToScreen()
			}
		})
		this.UserManager.events.addSilentRenewError((e) => {
			console.log('silent renew error', e.message)
		})

		this.UserManager.events.addAccessTokenExpired(() => {
			console.log('token expired')
			this.signinSilent()
		})
	}

	signinRedirectCallback = () => {
		this.UserManager.signinRedirectCallback()
			.then((user) => {
				console.log(user)
			})
			.catch((err) => console.log(err))
	}

	getUser = async () => {
		const user = await this.UserManager.getUser()
		if (!user) {
			return await this.UserManager.signinRedirectCallback()
		}
		return user
	}

	parseJwt = (token: string) => {
		const base64Url = token.split('.')[1]
		const base64 = base64Url.replace('-', '+').replace('_', '/')
		return JSON.parse(window.atob(base64))
	}

	signinRedirect = () => {
		localStorage.setItem('redirectUri', window.location.pathname)
		this.UserManager.signinRedirect({})
	}

	navigateToScreen = () => {
		window.location.replace('/')
	}

	isAuthenticated = () => {
		if (
			(window._env_.REACT_APP_SKIP_AUTH || process.env.REACT_APP_SKIP_AUTH) ===
			'true'
		)
			return true

		const val = sessionStorage.getItem(
			`oidc.user:${AUTH_URL}:${
				window._env_.REACT_APP_KC_CLIENT_ID ||
				process.env.REACT_APP_KC_CLIENT_ID
			}`
		)
		if (!val) return false

		const oidcStorage = JSON.parse(val ?? '')

		return !!oidcStorage && !!oidcStorage.access_token
	}

	signinSilent = () => {
		this.UserManager.signinSilent()
			.then((user) => {
				console.log('signed in', user)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	signinSilentCallback = () => {
		this.UserManager.signinSilentCallback()
	}

	createSigninRequest = () => {
		return this.UserManager.createSigninRequest()
	}

	logout = () => {
		this.UserManager.signoutRedirect({
			id_token_hint: localStorage.getItem('id_token'),
		})
		this.UserManager.clearStaleState()
	}

	signoutRedirectCallback = () => {
		this.UserManager.signoutRedirectCallback().then(() => {
			localStorage.clear()
			if (window._env_.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL)
				window.location.replace(
					window._env_.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL
				)
		})
		this.UserManager.clearStaleState()
	}
}
