import React from 'react'
import {
	Backdrop,
	Button,
	styled,
	Typography,
	CircularProgress,
	Container,
} from '@mui/material'

import { SubHeader } from 'Components/Header'
import { useNavigate } from 'react-router-dom'
import { NoDataComponent } from 'Components/Loader'

const ProjectContainer = styled(Container)(({ theme }) => ({
	color: theme.customPalette.text,
	height: 'calc(100% - 64px)',
	display: 'flex',
	paddingTop: '1.5rem',
	paddingBottom: '1.5rem',
	flexWrap: 'wrap',
	alignContent: 'flex-start',
}))

interface IProjects {
	projectsCount: number
	showBackdrop: boolean
	demoMode: boolean
}

const Projects: React.FunctionComponent<React.PropsWithChildren<IProjects>> = ({
	children,
	projectsCount,
	showBackdrop,
	demoMode,
}) => {
	const navigate = useNavigate()

	const addNewButton = (
		<Button
			color='secondary'
			variant='contained'
			size='small'
			onClick={() => navigate('/project/new')}
			disabled={demoMode}
		>
			ADD NEW
		</Button>
	)

	return (
		<>
			<SubHeader
				title='My projects'
				buttons={[
					{
						text: 'ADD NEW',
						variant: 'contained',
						onClick: () => navigate('/project/new'),
						disabled: demoMode,
					},
				]}
			/>
			<ProjectContainer maxWidth={false}>
				{projectsCount ? (
					children
				) : (
					<NoDataComponent>
						<>
							<Typography fontSize={20} marginBottom='2.5rem' maxWidth={300}>
								You don't have any projects analyzed yet
							</Typography>
							{addNewButton}
						</>
					</NoDataComponent>
				)}
			</ProjectContainer>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={showBackdrop}
			>
				<CircularProgress color='secondary' />
			</Backdrop>
		</>
	)
}

export default Projects
