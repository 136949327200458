import { createTheme } from '@mui/material/styles'

const cardBackgroundLight = '#5c6e85'
const cardBackground = '#293A52'
const hotPink = '#ff3399'
const text = '#C9D3F2'
const textDark = '#A0A7BB'
const turchese = '#41D3BD'

export const theme = createTheme({
  customPalette: {
    darkblue: '#162135',
    appBackgroud: '#0A192F',
    subheaderBackground: '#0F213C',
    cardBackground: cardBackground,
    cardBackgroundLight: cardBackgroundLight,
    title: textDark,
    text: text,
    turchese,
    codeHighlight: '#D32F2F55',
  },
  palette: {
    primary: {
      main: '#ff3399',
    },
    secondary: {
      main: hotPink,
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          color: textDark,
          fontSize: '20px',
        },
        h1: {
          fontFamily: 'Karla',
          fontSize: '26px',
          color: text,
        },
        h2: {
          fontFamily: 'Karla',
          fontSize: '22px',
        },
        h3: {
          fontSize: '18px',
        },
        subtitle2: {
          color: textDark,
          fontSize: '14px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: cardBackgroundLight,
          color: text,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &:hover, &.Mui-selected:hover': {
            color: turchese,
            backgroundColor: cardBackgroundLight,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: text,
          marginBottom: '1rem',
          '&:after': {
            borderBottomColor: turchese,
          },
          '&:before': {
            borderBottomColor: textDark,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottomColor: turchese,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: text,
          '&.Mui-checked': {
            color: turchese,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: textDark,
          '&.Mui-focused': {
            color: text,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: text,
          '&.Mui-checked': {
            color: turchese,
          },
          '&.Mui-disabled': {
            color: textDark,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiSelect-icon': {
            color: text,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: textDark,
          '&.Mui-selected': {
            color: text,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: turchese,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: text,
          backgroundColor: cardBackground,
          margin: '.25rem .5rem .25rem 0',
          '& .MuiChip-avatar': {
            color: text,
          },
          '&.chip-selected': {
            border: `.5px solid ${text}`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: text,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          background: cardBackground,
          color: text,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderTop: '24px solid #0A192F',
          '&:first-child': {
            borderTop: '0',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: text,
          borderBottom: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: text,
          boxShadow: 'none',
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          justifyContent: 'start',
        },
        content: {
          flexGrow: 0,
        },
        expandIconWrapper: {
          marginLeft: '.5rem',
          color: text,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '8px 0 16px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: textDark,
          },
        },
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface Theme {
    customPalette: {
      darkblue: string
      appBackgroud: string
      subheaderBackground: string
      cardBackground: string
      cardBackgroundLight: string
      title: string
      text: string
      turchese: string
      codeHighlight: string
    }
  }
  interface BaseTheme {
    customPalette: {
      darkblue: string
      appBackgroud: string
      subheaderBackground: string
      cardBackground: string
      cardBackgroundLight: string
      title: string
      text: string
      turchese: string
      codeHighlight: string
    }
  }
  interface ThemeOptions {
    customPalette?: {
      darkblue?: string
      appBackgroud?: string
      subheaderBackground?: string
      cardBackground?: string
      cardBackgroundLight?: string
      title?: string
      text?: string
      turchese?: string
      codeHighlight?: string
    }
  }
}
