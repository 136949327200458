import { Box, styled, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))

interface IGraphEditorOption {
  label: string
}

const GraphEditorGroup: React.FunctionComponent<
  PropsWithChildren<IGraphEditorOption>
> = ({ label, children }) => {
  return (
    <StyledBox>
      <Typography fontSize={18} variant='subtitle1'>
        {label}
      </Typography>
      {children}
    </StyledBox>
  )
}

export default GraphEditorGroup
