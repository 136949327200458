import * as React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import OverviewCard from 'Components/Card/OverviewCard'

import { getDefaultLine } from '../../lib/line'
import { getColoredGauge } from '../../lib/gauge'

import PesoIcon from '../../img/icons/peso_icon.svg'
import PuzzleIcon from '../../img/icons/puzzle_icon.svg'
import LineChartIcon from '../../img/icons/linechart_icon.svg'
import { TemporalStatistics } from 'Model/arcan-types'

interface IOverview {
	projectOverview: TemporalStatistics
}

const Overview = ({ projectOverview }: IOverview) => {
	let graphData: [string, number][] =
		projectOverview?.historical?.techDebtIndex?.map((val) => [
			val.version.versionDate,
			parseInt(val.value),
		]) ?? []

	return (
		<>
			<Grid
				container
				spacing={2}
				sx={{
					marginTop: '5px',
					marginBottom: '49px',
					width: '80%',
					margin: 'auto',
				}}
			>
				<Grid item xs={12} md={6}>
					<Card>
						<OverviewCard
							projectOverviewCard={{
								title: 'Technical Debt Dispersion',
								um: '',
								chart: {
									data: getColoredGauge(
										projectOverview?.trends?.techDebtDispersionMetric?.value ??
											0
									),
									target: projectOverview?.trends?.techDebtConcentrationName,
									legenda: [
										{
											color: '#7CFFB2',
											description: 'High concentration',
										},
										{
											color: '#FDDD60',
											description: 'Avarage dispersion',
										},
										{
											color: '#FF6E76',
											description: 'High dispersion',
										},
									],
								},
								tooltip:
									'Whether the amount of technical debt is concentrated in one component, or dispersed across several. Higher values imply that more components need to be refactored.',
							}}
						/>
					</Card>
				</Grid>
				<Grid item xs={12} md={3}>
					<OverviewCard
						projectOverviewCard={{
							title: 'Technical Debt Spread',
							value: projectOverview?.trends?.techDebtCoverage,
							um: '%',
							tooltip:
								'The percentage of connections between components that are affected by technical debt. Higher values imply that more elements are affected by technical debt.',
						}}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<OverviewCard
						projectOverviewCard={{
							title: 'Technical Debt',
							value: projectOverview?.trends?.techDebtHours,
							um: '',
							type: 'hours',
							tooltip:
								'An estimate of the time necessary to fix the technical debt in the system. The formula that translates the technical debt index into hours can be customized.',
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<OverviewCard
						projectOverviewCard={{
							title: 'Design Complexity',
							value: projectOverview?.trends?.spaghettiDesignRatio,
							um: '%',
							icon: {
								src: PuzzleIcon,
								alt: 'puzzle icon',
							},
							tooltip:
								'A measurement of how entangled the components in the system are. Higher values mean that the system is harder to understand and navigate.',
						}}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<OverviewCard
						projectOverviewCard={{
							title: 'Rigidity',
							um: '',
							icon: {
								src: PesoIcon,
								alt: 'weight icon',
							},
							chart: {
								data: getColoredGauge(
									projectOverview?.trends?.rigidity?.value ?? 0
								),
							},
							tooltip:
								'A measurement of how difficult it is to make a change. Higher values mean more difficulty to make a change for the risk of changing other parts of the system that are not semantically similar.',
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card>
						<OverviewCard
							projectOverviewCard={{
								title: 'Technical Debt Evolution',
								um: '',
								icon: {
									src: LineChartIcon,
									alt: 'linechart icon',
								},
								chart: { data: getDefaultLine(graphData) },
								tooltip:
									'The technical debt index. This is a value that represents the amount of effort necessary to remove all the debt from the system.',
							}}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	)
}

export default Overview
