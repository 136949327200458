import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import SmellListTable from 'Components/Table/SmellListTable'
import { IGetSmellList } from 'Queries/Smells'
import { ArchitecturalSmell } from 'Model/arcan-types'

interface IAssessment {
	projectAssessment: IGetSmellList
	setSelectedSmellId: Dispatch<SetStateAction<number | undefined>>
}

const Assesment = ({ projectAssessment, setSelectedSmellId }: IAssessment) => {
	const [SmellsList, setSmellLists] = useState<ArchitecturalSmell[]>([])

	useEffect(() => {
		if (projectAssessment?.projectById?.dependencyGraph?.archSmells) {
			setSmellLists(
				projectAssessment?.projectById?.dependencyGraph
					?.archSmells as ArchitecturalSmell[]
			)
			setSelectedSmellId(undefined)
		}
	}, [projectAssessment])

	return (
		<Grid
			container
			sx={{
				margin: '30px auto',
				width: '100%',
				maxWidth: '1200px',
			}}
		>
			<Grid item xs={12}>
				<Box sx={{ height: 490, width: '100%' }}>
					<SmellListTable
						data={SmellsList}
						setSelectedSmellId={setSelectedSmellId}
					/>
				</Box>
			</Grid>
		</Grid>
	)
}

export default Assesment

/*
icona tipo smell
progress in severity
aggiustare l'over sulla cella
mettere la pulsantiera sulla tabella
https://material-table.com/#/docs/features/detail-panel

adesso sto provando questo
https://codesandbox.io/s/wno91qwk98
*/
