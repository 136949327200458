import { Checkbox, FormControlLabel, styled } from '@mui/material'
import { Field, FieldProps } from 'formik'
import React from 'react'

interface ICustomCheckboxField {
  name: string
  label: React.ReactNode
}

const StyledControlLabel = styled(FormControlLabel)(() => ({
  width: '100%',
}))

const CustomCheckboxField = ({ name, label }: ICustomCheckboxField) => (
  <Field type='checkbox' name={name}>
    {({ field }: FieldProps) => (
      <StyledControlLabel control={<Checkbox {...field} />} label={label} />
    )}
  </Field>
)

export default CustomCheckboxField
