import { gql, useMutation, useQuery } from '@apollo/client'
import {
  AnalysisOptionsInput,
  AnalysisSummary,
  JobInfo,
  Licence,
} from 'Model/arcan-types'

export interface IAnalyseProject {
  analyseProject: AnalysisSummary
}

export interface IAnlyseProjectJob {
  analyseProjectJob: JobInfo
}

export interface ILicence {
  licence: Licence
}

export const LICENCE_QUERY = gql`
  query licence {
    licence {
      owner
      limitations{
        name
        limit
      }
    }
  }
`

export const useLicenceQuery = () => useQuery<ILicence | undefined>(LICENCE_QUERY)

export const ANALYSE_PROJECT_JOB = gql`
  mutation analyseProject($analysisOptions: AnalysisOptionsInput!) {
    analyseProjectJob(analysisOptions: $analysisOptions) {
      description
      ended
      id
      jobGroup
      jobName
      started
      startedBy
      status {
        name
        description
      }
      targetResourceId
    }
  }
`

export const useAnalyseProjectJobMutation = () =>
  useMutation<IAnlyseProjectJob, { analysisOptions: AnalysisOptionsInput }>(
    ANALYSE_PROJECT_JOB
  )

export interface IAnlyseProjectById {
  analyseProjectById: JobInfo
}

export const ANALYSE_PROJECT_BY_ID = gql`
  mutation analyseProject($projectId: Int!) {
    analyseProjectById(projectId: $projectId) {
      description
      ended
      id
      jobGroup
      jobName
      started
      startedBy
      status {
        name
        description
      }
      targetResourceId
    }
  }
`

export const useAnalyseProjectByNameMutation = () =>
  useMutation<IAnlyseProjectById, { projectId: number }>(ANALYSE_PROJECT_BY_ID)

export interface IUpdateProject {
  updateProject: boolean
}

export const UPDATE_PROJECET = gql`
  mutation updateProject($analysisOptions: AnalysisOptionsInput!) {
    updateProject(analysisOptions: $analysisOptions)
  }
`

export const useUpdateProjectMutation = () =>
  useMutation<IUpdateProject, { analysisOptions: AnalysisOptionsInput }>(
    UPDATE_PROJECET
  )
