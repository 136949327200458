import { gql, useLazyQuery } from '@apollo/client'
import { Project } from 'Model/arcan-types'

interface IGetTreeDG {
  projectById: Project
}

export const GET_TREE_DG = gql`
  query getdependencyGraph($projectId: Int!, $versionId: String!) {
    projectById(projectId: $projectId) {
      dependencyGraph(versionId: $versionId) {
        allUnits {
          id
          label
          name
          simpleName
          relativeFilePath
          properties {
            key
            value
          }
          constructType {
            name
            prettyName
          }
        }
        allContainers {
          id
          label
          name
          simpleName
          relativeFilePath
          constructType {
            name
            prettyName
          }
          properties {
            key
            value
          }
        }
        membershipEdges {
          id
          label
          member {
            id
          }
          parent {
            id
          }
        }
        dependencyEdges {
          id
          label
          weight
          dependedUpon {
            id
          }
          dependant {
            id
          }
        }
        hierarchyEdges {
          id
          label
          parent {
            id
          }
          children {
            id
          }
        }
      }
    }
  }
`

export const useGetTreeDGLazy = () =>
  useLazyQuery<IGetTreeDG, { projectId: number; versionId: string }>(
    GET_TREE_DG
  )
