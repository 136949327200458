import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material'
import React, { useState } from 'react'

interface IDialogProps {
	open: boolean
	title: string
	body: string
	btnConfirmText: string
	confirmAction: () => void
}

const ConfirmDialogContext = React.createContext({
	openDialog: (props: IDialogProps) => {},
})

export const ConfirmDialogProvider: React.FunctionComponent<
	React.PropsWithChildren<{}>
> = ({ children }) => {
	const [DialogProps, setDialogProps] = useState<IDialogProps>({
		open: false,
		title: '',
		body: '',
		btnConfirmText: '',
		confirmAction: () => {},
	})

	const handleClose = () => setDialogProps((prev) => ({ ...prev, open: false }))

	const openDialog = (props: IDialogProps) => setDialogProps(props)

	return (
		<ConfirmDialogContext.Provider value={{ openDialog }}>
			{children}
			<Dialog open={DialogProps.open} onClose={handleClose}>
				<DialogTitle>{DialogProps.title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{DialogProps.body}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='inherit' variant='outlined'>
						Cancel
					</Button>
					<Button
						variant='outlined'
						onClick={() => {
							handleClose()
							DialogProps.confirmAction()
						}}
					>
						{DialogProps.btnConfirmText}
					</Button>
				</DialogActions>
			</Dialog>
		</ConfirmDialogContext.Provider>
	)
}

export const useConfirmDialog = () => React.useContext(ConfirmDialogContext)
