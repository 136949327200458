import { Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

interface IGraphEditorSubGroup {
	label: string
}

const GraphEditorSubGroup: React.FunctionComponent<
	PropsWithChildren<IGraphEditorSubGroup>
> = ({ label, children }) => {
	return (
		<div>
			<Typography variant='subtitle2'>{label}</Typography>
			{children}
		</div>
	)
}

export default GraphEditorSubGroup
