import { ProjectInput } from 'Model/arcan-types'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'Components/Context/SnackbarContext'
import WithLoading from 'Components/HOC/WithLoading'
import { NewProject } from 'Templates/Projects'
import { useGetEnum } from 'Queries/Enum'
import { useMutationCreateProject, useMutationCreateProjectUpload } from 'Queries/Projects'
import { useLazyGetJobInfo } from 'Queries/Job'

const NewProjectWithLoading = WithLoading(NewProject)

const NewProjectData = () => {
	const [LoadingPolling, setLoadingPolling] = useState(false)

	const navigate = useNavigate()
	const [newProject, { loading }] = useMutationCreateProject()
	const [newProjectWithUpload] = useMutationCreateProjectUpload()
	const [getJobInfo, { data, stopPolling }] = useLazyGetJobInfo()
	const { enumValues, loading: loadingEnum } = useGetEnum('ProgrammingLanguage')
	const { handleOpen } = useSnackbar()

	useEffect(() => {
		if (data?.jobInfo) {
			setLoadingPolling(true)

			if (data?.jobInfo.ended) {
				setLoadingPolling(false)
				stopPolling()
				const {
					status: { name },
					targetResourceId,
					raisedExceptionMessage,
				} = data.jobInfo
				if (name === 'COMPLETE') {
					handleOpen('Project created!', 'success')
					navigate(`/project/new/${targetResourceId}/tree`)
				} else if (raisedExceptionMessage) {
					handleOpen(raisedExceptionMessage, 'error')
				}
			}
		}
	}, [data])

	const submitAction = (project: ProjectInput) => {
		handleOpen('Creating new project')
		newProject({
			variables: {
				project,
			},
		})
		.then(({ data }) => {
			if (data?.newProjectJob) {
				handleOpen('Creating new project', 'success')
				getJobInfo({
					variables: {
						jobId: data.newProjectJob.id,
					},
					pollInterval: 3000,
				})
			} else {
				handleOpen('Error during creation!', 'error')
			}
		})
		.catch((err) => {
			handleOpen(`${err}`, 'error')
		})
	}

	const submitActionWithUpload = (project: ProjectInput, file: File) => {
		handleOpen('Creating and uploading project')
		newProjectWithUpload({
			variables: {
				project,
				file
			},
		}).then(({ data }) => {
			if (data?.newProjectUpload?.success) {
				handleOpen('Project created successfully', 'success')
				navigate(`/project/new/${data.newProjectUpload.resourceId}/tree`)
			} else {
				handleOpen('Error during creation!', 'error')
			}
		})
		.catch((err) => {
			handleOpen(`Connection refused. Upload size may exceed server's limit.`, 'error')
		})
	}

	function getPrettyLanguage(val: string): string{
		if(val === "PYTHON"){
			return "PYTHON (BETA)"
		}else if( val === "CSHARP"){
			return "C#"
		}else{
			return val;
		}
	}

	return (
		<NewProjectWithLoading
			submitAction={submitAction}
			submitActionWithUpload={submitActionWithUpload}
			submitting={loading || LoadingPolling}
			loading={loadingEnum}
			programmingLanguages={enumValues.map((val) => ({
				value: val.name,
				text: getPrettyLanguage(val.name)
			}))}
		/>
	)
}

export default NewProjectData
