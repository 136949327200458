import React from 'react'
import { Button, InputAdornment, styled, TextField } from '@mui/material'
import FilterNoneIcon from '@mui/icons-material/FilterNone'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import SearchIcon from '@mui/icons-material/Search'

const OptionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: `${theme.spacing(0.5)} 0`,
  '& .MuiInputAdornment-root': {
    color: theme.customPalette.text,
  },
}))

let timeout: NodeJS.Timeout

interface ITreeOptionsPanel {
  handleSelectAll: () => void
  handleDeselectAll: () => void
  handleSearch: (arg0: string) => void
}

const TreeOptionsPanel = ({
  handleSelectAll,
  handleDeselectAll,
  handleSearch,
}: ITreeOptionsPanel) => {
  return (
    <OptionsContainer>
      <TextField
        label='Search element'
        variant='standard'
        onChange={({ target: { value } }) => {
          if (timeout) clearTimeout(timeout)

          timeout = setTimeout(() => {
            handleSearch(value)
          }, 1000)
        }}
        sx={{ maxWidth: 300 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant='outlined'
        color='primary'
        startIcon={<LibraryAddCheckOutlinedIcon />}
        sx={{ margin: 'auto 1rem' }}
        onClick={handleSelectAll}
      >
        SELECT ALL
      </Button>
      <Button
        variant='outlined'
        color='primary'
        startIcon={<FilterNoneIcon />}
        sx={{ margin: 'auto 0' }}
        onClick={handleDeselectAll}
      >
        UNSELECT ALL
      </Button>
    </OptionsContainer>
  )
}

export default TreeOptionsPanel
