import { gql, useLazyQuery } from '@apollo/client'
import { JobInfo } from 'Model/arcan-types'

interface IGetJobInfo {
  jobInfo: JobInfo
}

export const GET_JOB_INFO = gql`
  query getJobInfo($jobId: String!) {
    jobInfo(id: $jobId) {
      id
      jobGroup
      jobName
      description
      status {
        name
        description
      }
      raisedExceptionMessage
      startedBy
      started
      ended
      targetResourceId
    }
  }
`

export const useLazyGetJobInfo = () =>
  useLazyQuery<IGetJobInfo, { jobId: string }>(GET_JOB_INFO, {
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
    },
  })
