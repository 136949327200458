import React, { useRef, useEffect } from 'react'
import { init, EChartOption, getInstanceByDom } from 'echarts'

interface IChart {
	options: EChartOption
}

const Chart = ({ options }: IChart) => {
	options.legend = {
		top: '0',
		left: 'left',
		textStyle: {
			color: '#A0A7BB',
		},
	}
	const myChart = useRef<HTMLDivElement>(null)
	useEffect(() => {
		if (myChart?.current) {
			let chart = getInstanceByDom(myChart.current)
			if (!chart) chart = init(myChart.current)

			chart.setOption(options, false)
		}
	}, [options])

	useEffect(() => {
		window.addEventListener('resize', () => {
			if (myChart?.current) {
				let chart = getInstanceByDom(myChart.current)
				if (chart) chart.resize()
			}
		})
	}, [])

	return (
		<div
			ref={myChart}
			style={{
				width: '100%',
				height: '100%',
			}}
		></div>
	)
}

export default Chart
