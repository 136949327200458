import { gql, useLazyQuery } from '@apollo/client'
import { Project } from '../Model/arcan-types'

export interface IGetSmellList {
	projectById: Project
}

export const GET_SMELLS = gql`
	query getSmells($projectId: Int!, $versionId: String!) {
		projectById(projectId: $projectId) {
			dependencyGraph(versionId: $versionId) {
				archSmells {
					id
					type
					fullTypeName
					affectedConstructType {
						name
						prettyName
					}
					size
					properties {
						key #ATDI (as TDI, round), Severity
						value
					}
					affectedComponents {
						name
					}
					cardInfo {
						fanIn
						fanOut
						affectedCentre
						detectionRule
						detectionValue
						detectionThreshold
					}
				}
			}
		}
	}
`

export const useGetSmells = () =>
	useLazyQuery<IGetSmellList, { projectId: number; versionId: string }>(
		GET_SMELLS
	)

export interface IGetSmellGraph {
	projectById: Project
}

export const GET_SMELL_GRAPH = gql`
	query smellGraph($projectId: Int!, $versionId: String!, $smellId: Int!) {
		projectById(projectId: $projectId) {
			dependencyGraph(versionId: $versionId) {
				smellById(smellId: $smellId) {
					id
					label
					properties {
						key
						value
					}
					fullTypeName
					affectedConstructType {
						prettyName
					}
					affectedComponents {
						name
					}
					size
					smellDependencyEdges {
						id
						dependant {
							id
							name
						}
						dependedUpon {
							id
							name
						}
					}
				}
			}
		}
	}
`

export const useGetSmellGraph = () =>
	useLazyQuery<
		IGetSmellGraph,
		{ projectId: number; versionId: string; smellId: number }
	>(GET_SMELL_GRAPH)

export const GET_SMELL_SOURCE_BY_VI = gql`
	query getByVertexId($projectId: Int!, $versionId: String!, $vertexId: Int!) {
		projectById(projectId: $projectId) {
			dependencyGraph(versionId: $versionId) {
				edgeSourcesTreeVertex(vertexId: $vertexId) {
					edge {
						weight
						dependencyAmongUnits
						id
						dependant {
							id
							name
							properties {
								key
								value
							}
						}
						dependedUpon {
							name
							properties {
								key
								value
							}
						}
					}
					sources {
						codeSnippet {
							fileLines
							locations {
								line
								endLine
								column
								columnEnd
							}
						}
						weight
						dependant {
							id
							name
							properties {
								key
								value
							}
						}
						dependedUpon {
							id
							name
						}
					}
				}
			}
		}
	}
`

export const useLazyGetSmellByVi = () =>
	useLazyQuery<
		{ projectById: Project },
		{ projectId: number; versionId: string; vertexId: number }
	>(GET_SMELL_SOURCE_BY_VI)

export const GET_SMELL_SOURCE_BY_EI = gql`
	query getByEdgeId(
		$projectId: Int!
		$versionId: String!
		$dependencyEdgeId: Int!
	) {
		projectById(projectId: $projectId) {
			dependencyGraph(versionId: $versionId) {
				edgeSourcesTree(dependencyEdgeId: $dependencyEdgeId) {
					edge {
						weight
						dependencyAmongUnits
						id
						dependant {
							id
							name
							properties {
								key
								value
							}
						}
						dependedUpon {
							name
							properties {
								key
								value
							}
						}
					}
					sources {
						codeSnippet {
							fileLines
							locations {
								line
								endLine
								column
								columnEnd
							}
						}
						weight
						dependant {
							id
							name
							properties {
								key
								value
							}
						}
						dependedUpon {
							id
							name
						}
					}
				}
			}
		}
	}
`

export const useLazyGetSmellByEi = () =>
	useLazyQuery<
		{ projectById: Project },
		{ projectId: number; versionId: string; dependencyEdgeId: number }
	>(GET_SMELL_SOURCE_BY_EI)
