import {
  alpha,
  Input,
  InputBase,
  styled,
  Switch,
  Typography,
} from '@mui/material'
import React, { FunctionComponent, PropsWithChildren } from 'react'

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.customPalette.text,
  display: 'flex',
  maxWidth: 355,
  '&:last-of-type': {
    marginBottom: theme.spacing(1),
  },
}))

const StyledLabel = styled('strong')(() => ({
  margin: 'auto 0',
}))

interface IGraphEditorOption {
  label: string
}

const GraphEditorOption: FunctionComponent<
  PropsWithChildren<IGraphEditorOption>
> = ({ label, children }) => {
  return (
    <StyledTypography fontSize={18} variant='body1'>
      <StyledLabel>{label}</StyledLabel>
      {children}
    </StyledTypography>
  )
}

export default GraphEditorOption
