import { CircularProgress, Skeleton, styled } from '@mui/material'
import React from 'react'
import { SubHeader } from 'Components/Header'
import { useAuth } from './AuthContext'

const LoadinContainer = styled('div')(() => ({
	margin: '15rem auto 0',
	textAlign: 'center',
}))

const SilentRenew = () => {
	const { signinSilentCallback } = useAuth()
	signinSilentCallback()

	return (
		<>
			<SubHeader title={<Skeleton />} />
			<LoadinContainer>
				<CircularProgress color='secondary' />
			</LoadinContainer>
		</>
	)
}

export default SilentRenew
