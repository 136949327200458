import { Switch, styled, InputBase } from '@mui/material'
import { alpha } from '@mui/system'
import React from 'react'

const StyledSwitch = styled(Switch)(({ theme }) => ({
	margin: `0 ${theme.spacing(1)} 0 auto`,
	'& .MuiSwitch-switchBase': {
		color: theme.customPalette.title,
		'&:hover': {
			backgroundColor: alpha(
				theme.customPalette.title,
				theme.palette.action.hoverOpacity
			),
		},
	},
	'& .MuiSwitch-switchBase + .MuiSwitch-track': {
		backgroundColor: theme.customPalette.title,
	},
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: theme.customPalette.turchese,
		'&:hover': {
			backgroundColor: alpha(
				theme.customPalette.turchese,
				theme.palette.action.hoverOpacity
			),
		},
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: theme.customPalette.turchese,
	},
}))

const StyeldInputBase = styled(InputBase)(() => ({
	width: 20,
}))

interface IGraphEditorColor {
	selected?: boolean
	setSelected: (arg0: boolean) => void
	color: string
	setColor: (arg0: string) => void
}

let timeout: NodeJS.Timeout

const GraphEditorColor = ({
	selected,
	setSelected,
	color,
	setColor,
}: IGraphEditorColor) => {
	return (
		<>
			<StyledSwitch
				checked={selected}
				onChange={(_, checked) => setSelected(checked)}
			/>
			<StyeldInputBase
				type='color'
				value={color}
				onChange={({ target: { value } }) => {
					if (timeout) clearTimeout(timeout)

					timeout = setTimeout(() => setColor(value), 500)
				}}
			/>
		</>
	)
}

export default GraphEditorColor
