import React from 'react'
import { CircularProgress, Skeleton, styled } from '@mui/material'
import { SubHeader } from '../Header'

const LoadingContainer = styled('div')(() => ({
	margin: '15rem auto 0',
	textAlign: 'center',
}))

interface WithLoadingProps {
	loading: boolean
	showHeader?: boolean
}

const WithLoading = <T extends object>(
	Component: React.ComponentType<T>
): React.FunctionComponent<T & WithLoadingProps> => {
	return ({ loading, showHeader = true, ...props }) => {
		return loading ? (
			<>
				{showHeader && <SubHeader title={<Skeleton />} />}
				<LoadingContainer>
					<CircularProgress color='secondary' />
				</LoadingContainer>
			</>
		) : (
			<Component {...(props as T)} />
		)
	}
}

export default WithLoading
