import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Field, FieldProps } from 'formik'
import React from 'react'

interface ICustomRadioField {
  name: string
  label: string
  children: {
    value: string
    text: string
    disabled?: boolean
  }[]
}

const CustomRadioField = ({ name, label, children }: ICustomRadioField) => (
  <FormControl>
    <FormLabel id={`${name}-label`}>{label}</FormLabel>
    <RadioGroup
      row
      aria-labelledby={`${name}-label`}
      name={name}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      {children.map(({ value, text, disabled = false }, i) => (
        <Field type='radio' name={name} value={value} key={i}>
          {({ field }: FieldProps) => (
            <FormControlLabel
              disabled={disabled}
              control={<Radio />}
              label={text}
              {...field}
            />
          )}
        </Field>
      ))}
    </RadioGroup>
  </FormControl>
)

export default CustomRadioField
