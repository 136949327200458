import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import WithLoading from 'Components/HOC/WithLoading'
import { ArchitecturalSmell, DependencyEdge } from 'Model/arcan-types'
import Assessment from 'Templates/Project/Assessment'
import SmellDetail from 'Templates/Project/Assessment/SmellDetail'
import {
	useGetSmells,
	IGetSmellList,
	useGetSmellGraph,
	useLazyGetSmellByVi,
	useLazyGetSmellByEi,
} from 'Queries/Smells'

const AssessmentWithLoading = WithLoading(Assessment)
const SmellDetailWithLoading = WithLoading(SmellDetail)

const AssessmentData = () => {
	const [SelectedSmellId, setSelectedSmellId] = useState<number>()
	const [Edges, setEdges] = useState<DependencyEdge[]>([])
	const [SmellDetailTableTitle, setSmellDetailTableTitle] = useState('')

	const { projectId, versionId } =
		useParams<{ projectId: string; versionId: string }>()
	const [getSmells, { loading, data }] = useGetSmells()
	const [getSmellGraph, { loading: loadingGraph, data: dataGrap }] =
		useGetSmellGraph()
	const [getEdgeByVi, { loading: loadingVi, data: dataVi }] =
		useLazyGetSmellByVi()
	const [getEdgeByEi, { loading: loadingEi, data: dataEi }] =
		useLazyGetSmellByEi()

	useEffect(() => {
		if (projectId) {
			getSmells({
				variables: {
					projectId: parseInt(projectId),
					versionId: versionId ?? '',
				},
			})
		}
	}, [projectId, versionId])

	useEffect(() => {
		if (projectId && versionId && SelectedSmellId) {
			getSmellGraph({
				variables: {
					projectId: parseInt(projectId),
					versionId,
					smellId: SelectedSmellId,
				},
			})

			setEdges([])
		}
	}, [projectId, versionId, SelectedSmellId])

	useEffect(() => {
		if (dataVi?.projectById) {
			const { edgeSourcesTreeVertex } = dataVi.projectById.dependencyGraph

			var flattedSources = edgeSourcesTreeVertex.flat()

			var edges = flattedSources
				.filter((edgeSource) => edgeSource.edge.dependencyAmongUnits)
				.map((edgeSource) => edgeSource.edge)
			edges = edges.concat(
				flattedSources
					.filter((edge) => edge.sources.length)
					.map((edge) => edge.sources)
					.flat()
			)

			setEdges(edges)
		}
	}, [dataVi])

	useEffect(() => {
		if (dataEi?.projectById) {
			const { edgeSourcesTree } = dataEi.projectById.dependencyGraph

			var flattedSources = edgeSourcesTree.flat()

			var edges = flattedSources
				.filter((edgeSource) => edgeSource.edge.dependencyAmongUnits)
				.map((edgeSource) => edgeSource.edge)
			edges = edges.concat(
				flattedSources
					.filter((edge) => edge.sources.length)
					.map((edge) => edge.sources)
					.flat()
			)

			setEdges(edges)
		}
	}, [dataEi])

	return SelectedSmellId ? (
		<SmellDetailWithLoading
			smell={
				dataGrap?.projectById?.dependencyGraph?.smellById ??
				({} as ArchitecturalSmell)
			}
			backAction={() => {
				setSelectedSmellId(undefined)
				setSmellDetailTableTitle('')
			}}
			loading={loadingGraph}
			showHeader={false}
			edges={Edges}
			tableTitle={SmellDetailTableTitle}
			handleSelectItem={(item, type) => {
				switch (type) {
					case 'edge':
						getEdgeByEi({
							variables: {
								projectId: parseInt(projectId!),
								versionId: versionId!,
								dependencyEdgeId: item.data().id,
							},
						})

						setSmellDetailTableTitle(
							`Dependency sources: ${(item as any).source().data().name} -> ${
								(item as any).target().data().name
							}`
						)
						break
					case 'node':
						getEdgeByVi({
							variables: {
								projectId: parseInt(projectId!),
								versionId: versionId!,
								vertexId: item.data().id,
							},
						})
						setSmellDetailTableTitle(`Dependency sources: ${item.data().name}`)
						break
				}
			}}
		/>
	) : (
		<AssessmentWithLoading
			loading={loading}
			showHeader={false}
			projectAssessment={(data ? data : {}) as IGetSmellList}
			setSelectedSmellId={setSelectedSmellId}
		/>
	)
}

export default AssessmentData
