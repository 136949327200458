export const AUTH_URL = `${window._env_.REACT_APP_KC_URL || process.env.REACT_APP_KC_URL}/realms/${window._env_.REACT_APP_KC_REALM || process.env.REACT_APP_KC_REALM}`

export const IDENTITY_CONFIG = {
	authority: AUTH_URL, //(string): The URL of the OIDC provider.
	client_id: window._env_.REACT_APP_KC_CLIENT_ID || process.env.REACT_APP_KC_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
	redirect_uri: `${window._env_.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL}/signin-oidc`, //The URI of your client application to receive a response from the OIDC provider.
	loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
	silent_redirect_uri: `${window._env_.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL}/silentrenew`, //(string): The URL for the page containing the code handling the silent renew.
	post_logout_redirect_uri: `${window._env_.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL}/logout/callback`, // (string): The OIDC post-logout redirect URI.
	response_type: 'id_token token', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
	scope: 'openid profile address email phone offline_access', //(string, default: 'openid'): The scope being requested from the OIDC provider.
	filterProtocolClaims: true,
}

export const METADATA_OIDC = {
	issuer: AUTH_URL,
	authorization_endpoint: `${AUTH_URL}/protocol/openid-connect/auth`,
	token_endpoint: `${AUTH_URL}/protocol/openid-connect/token`,
	introspection_endpoint: `${AUTH_URL}/protocol/openid-connect/token/introspect`,
	userinfo_endpoint: `${AUTH_URL}/protocol/openid-connect/userinfo`,
	end_session_endpoint: `${AUTH_URL}/protocol/openid-connect/logout`,
	frontchannel_logout_session_supported: true,
	frontchannel_logout_supported: true,
	jwks_uri: `${AUTH_URL}/protocol/openid-connect/certs`,
	check_session_iframe: `${AUTH_URL}/protocol/openid-connect/login-status-iframe.html`,
	grant_types_supported: [
		'authorization_code',
		'implicit',
		'refresh_token',
		'password',
		'client_credentials',
		'urn:ietf:params:oauth:grant-type:device_code',
		'urn:openid:params:grant-type:ciba',
	],
	acr_values_supported: ['0', '1'],
	response_types_supported: [
		'code',
		'none',
		'id_token',
		'token',
		'id_token token',
		'code id_token',
		'code token',
		'code id_token token',
	],
	subject_types_supported: ['public', 'pairwise'],
	id_token_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'HS256',
		'HS512',
		'ES256',
		'RS256',
		'HS384',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
	],
	id_token_encryption_alg_values_supported: [
		'RSA-OAEP',
		'RSA-OAEP-256',
		'RSA1_5',
	],
	id_token_encryption_enc_values_supported: [
		'A256GCM',
		'A192GCM',
		'A128GCM',
		'A128CBC-HS256',
		'A192CBC-HS384',
		'A256CBC-HS512',
	],
	userinfo_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'HS256',
		'HS512',
		'ES256',
		'RS256',
		'HS384',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
		'none',
	],
	userinfo_encryption_alg_values_supported: [
		'RSA-OAEP',
		'RSA-OAEP-256',
		'RSA1_5',
	],
	userinfo_encryption_enc_values_supported: [
		'A256GCM',
		'A192GCM',
		'A128GCM',
		'A128CBC-HS256',
		'A192CBC-HS384',
		'A256CBC-HS512',
	],
	request_object_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'HS256',
		'HS512',
		'ES256',
		'RS256',
		'HS384',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
		'none',
	],
	request_object_encryption_alg_values_supported: [
		'RSA-OAEP',
		'RSA-OAEP-256',
		'RSA1_5',
	],
	request_object_encryption_enc_values_supported: [
		'A256GCM',
		'A192GCM',
		'A128GCM',
		'A128CBC-HS256',
		'A192CBC-HS384',
		'A256CBC-HS512',
	],
	response_modes_supported: [
		'query',
		'fragment',
		'form_post',
		'query.jwt',
		'fragment.jwt',
		'form_post.jwt',
		'jwt',
	],
	registration_endpoint: `${AUTH_URL}/clients-registrations/openid-connect`,
	token_endpoint_auth_methods_supported: [
		'private_key_jwt',
		'client_secret_basic',
		'client_secret_post',
		'tls_client_auth',
		'client_secret_jwt',
	],
	token_endpoint_auth_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'HS256',
		'HS512',
		'ES256',
		'RS256',
		'HS384',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
	],
	introspection_endpoint_auth_methods_supported: [
		'private_key_jwt',
		'client_secret_basic',
		'client_secret_post',
		'tls_client_auth',
		'client_secret_jwt',
	],
	introspection_endpoint_auth_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'HS256',
		'HS512',
		'ES256',
		'RS256',
		'HS384',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
	],
	authorization_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'HS256',
		'HS512',
		'ES256',
		'RS256',
		'HS384',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
	],
	authorization_encryption_alg_values_supported: [
		'RSA-OAEP',
		'RSA-OAEP-256',
		'RSA1_5',
	],
	authorization_encryption_enc_values_supported: [
		'A256GCM',
		'A192GCM',
		'A128GCM',
		'A128CBC-HS256',
		'A192CBC-HS384',
		'A256CBC-HS512',
	],
	claims_supported: [
		'aud',
		'sub',
		'iss',
		'auth_time',
		'name',
		'given_name',
		'family_name',
		'preferred_username',
		'email',
		'acr',
	],
	claim_types_supported: ['normal'],
	claims_parameter_supported: true,
	scopes_supported: [
		'openid',
		'phone',
		'web-origins',
		'address',
		'microprofile-jwt',
		'profile',
		'roles',
		'email',
		'offline_access',
	],
	request_parameter_supported: true,
	request_uri_parameter_supported: true,
	require_request_uri_registration: true,
	code_challenge_methods_supported: ['plain', 'S256'],
	tls_client_certificate_bound_access_tokens: true,
	revocation_endpoint: `${AUTH_URL}/protocol/openid-connect/revoke`,
	revocation_endpoint_auth_methods_supported: [
		'private_key_jwt',
		'client_secret_basic',
		'client_secret_post',
		'tls_client_auth',
		'client_secret_jwt',
	],
	revocation_endpoint_auth_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'HS256',
		'HS512',
		'ES256',
		'RS256',
		'HS384',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
	],
	backchannel_logout_supported: true,
	backchannel_logout_session_supported: true,
	device_authorization_endpoint: `${AUTH_URL}/protocol/openid-connect/auth/device`,
	backchannel_token_delivery_modes_supported: ['poll', 'ping'],
	backchannel_authentication_endpoint: `${AUTH_URL}/protocol/openid-connect/ext/ciba/auth`,
	backchannel_authentication_request_signing_alg_values_supported: [
		'PS384',
		'ES384',
		'RS384',
		'ES256',
		'RS256',
		'ES512',
		'PS256',
		'PS512',
		'RS512',
	],
	require_pushed_authorization_requests: false,
	pushed_authorization_request_endpoint: `${AUTH_URL}/protocol/openid-connect/ext/par/request`,
	mtls_endpoint_aliases: {
		token_endpoint: `${AUTH_URL}/protocol/openid-connect/token`,
		revocation_endpoint: `${AUTH_URL}/protocol/openid-connect/revoke`,
		introspection_endpoint: `${AUTH_URL}/protocol/openid-connect/token/introspect`,
		device_authorization_endpoint: `${AUTH_URL}/protocol/openid-connect/auth/device`,
		registration_endpoint: `${AUTH_URL}/clients-registrations/openid-connect`,
		userinfo_endpoint: `${AUTH_URL}/protocol/openid-connect/userinfo`,
		pushed_authorization_request_endpoint: `${AUTH_URL}/protocol/openid-connect/ext/par/request`,
		backchannel_authentication_endpoint: `${AUTH_URL}/protocol/openid-connect/ext/ciba/auth`,
	},
}
